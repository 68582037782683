import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Layout from "src/layout"
import { COLORS } from "src/layout/constants"
import { Subtitle, Section, Row, Column, Text } from "src/components/styled"
import { lowercase } from "src/components/Name"
import Inspirations from "src/components/Inspirations"
import { useLang } from "src/layout/root"
import Spec from "src/components/Spec"

const Specs = styled.div`
  h3 {
    text-transform: initial;
    font-weight: 500;
    font-size: 2em;
    margin: 1.7em 0 0;
  }
  h4 {
    color: ${COLORS.grey};
    margin: 5px 0 20px;
  }
`

interface DataType {
  custom: { childImageSharp: Media }
  inspirations: {
    nodes: Project[] | null
  }
}

const CustomPage: GatsbyPage<DataType> = ({ data }) => {
  const { getNodes } = useLang()
  const inspirations = getNodes<Project>(data.inspirations)

  return (
    <Layout title="custom">
      <Section>
        <Row>
          <Column>
            <FormattedMessage id="custom.label" tagName={Subtitle} />
            <FormattedMessage id="custom.title" tagName="h1" />
            <FormattedMessage id="custom.text" tagName={Text} values={lowercase} />
            <Specs>
              <FormattedMessage id="custom.formats" tagName="h3" />
              <FormattedMessage id="custom.specs" tagName="h4" />
              <Spec id="custom.standard" value="100 cm x 60 cm" />
              <Spec id="custom.maximum" value="200 cm x 100 cm" />
            </Specs>
          </Column>
          <Column>
            <Image fluid={data.custom.childImageSharp.fluid} />
          </Column>
        </Row>
      </Section>
      <Inspirations list={inspirations} />
    </Layout>
  )
}

export default CustomPage

export const query = graphql`
  query {
    custom: file(relativePath: { eq: "products/custom.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    inspirations: allContentfulProjet(filter: { tags: { eq: "sur-mesure" } }) {
      nodes {
        node_locale
        slug
        photo {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
