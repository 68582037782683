import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"
import { Link } from "gatsby"

import { COLORS, SIZES } from "src/layout/constants"

const Container = styled.section`
  background-color: ${COLORS.light};
  padding: ${SIZES.margin}px;
  h1 {
    font-family: Jost, sans-serif;
    font-weight: 500;
    text-align: center;
    margin: ${SIZES.margin}px 0;
  }
  @media (max-width: 799px) {
    padding: ${SIZES.mobileMargin / 2}px 0;
  }
`
const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 ${SIZES.margin / 2}px;
  @media (max-width: 799px) {
    padding: 0 ${SIZES.mobileMargin / 2}px;
  }
`
const ThumbLink = styled(Link)`
  flex: 0 1 50%;
  min-width: 300px;
  padding: ${SIZES.margin / 2}px;
  @media (max-width: 799px) {
    padding: ${SIZES.mobileMargin / 2}px;
    flex-grow: 1;
  }
`

type Inspiration = Pick<Project, "slug" | "photo">

interface Props {
  boxed?: boolean
  list: Inspiration[] | null
}

const Inspirations = ({ boxed, list }: Props) => {
  if (!list || !list.length) {
    return null
  }

  const content = (
    <Gallery>
      {list.map(({ slug, photo }) => (
        <ThumbLink to={`/inspirations/${slug}/`} key={slug}>
          <Image className="contrast" fluid={photo.fluid} />
        </ThumbLink>
      ))}
    </Gallery>
  )

  if (boxed) {
    return (
      <Container>
        <FormattedMessage id="menu.inspirations" tagName="h1" />
        {content}
      </Container>
    )
  }

  return content
}

export default Inspirations
