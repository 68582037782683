import React, { ReactNode } from "react"
import styled from "styled-components"
import Image from "gatsby-image"

import { COLORS } from "src/layout/constants"
import RichText from "src/components/RichText"
import { FormattedMessage } from "react-intl"

const Row = styled.div`
  padding: 4px 0;
  &:first-of-type {
    border-top: 1px solid ${COLORS.black};
  }
  border-bottom: 1px solid ${COLORS.black};
`
const Line = styled.div`
  display: flex;
  justify-content: space-between;
  strong {
    white-space: nowrap;
  }
  span {
    text-align: right;
  }
`

interface Props {
  id?: string
  name?: string
  value?: ReactNode
  suffix?: string
  description?: string | RichText
  image?: Media
}

const Spec = ({ id, name, value, suffix, description, image }: Props) => {
  const label = id ? <FormattedMessage id={id} tagName="strong" /> : <strong>{name}</strong>

  return (
    <Row>
      <Line>
        {label}
        {value && (
          <span>
            {value} {suffix && <FormattedMessage id={suffix} />}
          </span>
        )}
      </Line>
      {description && (typeof description === "string" ? description : <RichText node={description} />)}
      {image && <Image fluid={image.fluid} />}
    </Row>
  )
}

export default Spec
